
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"10 min read","minutes":9.37,"time":562199.9999999999,"words":1874}
/* @jsx mdx */
import { Shortcut, ShortcutTable } from '../components/Docs/Shortcut';
import Mouse from '../components/Docs/Mouse';
import {
  KeyIcon,
  IconRow,
  Plus,
  MetaKey,
  OptionKey,
  InlineIcons,
  Sidenote,
  Or,
} from '../components/Docs/ShortcutHelpers';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Beatmapper makes heavy use of keyboard (and mouse) shortcuts. You can make maps without learning any of them, but your life will be much easier if you take the time to familiarize yourself with them :)`}</p>
    <h2 {...{
      "id": "general-shortcuts"
    }}>{`General shortcuts`}</h2>
    <p>{`These shortcuts can be used either in the “Notes” view or the “Events” view.`}</p>
    <ShortcutTable mdxType="ShortcutTable">
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">
          <MetaKey mdxType="MetaKey" />
        </KeyIcon>
        <KeyIcon mdxType="KeyIcon">S</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Download map
    <Sidenote mdxType="Sidenote">Save a copy of the current map. Do this frequently!</Sidenote>
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">Space</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Play / pause the music.
    <Sidenote mdxType="Sidenote">Will snap to the nearest beat when pausing</Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">↑</KeyIcon>
        </IconRow>
        <IconRow mdxType="IconRow">
          <Or mdxType="Or" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">→</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Seek forwards
    <Sidenote mdxType="Sidenote">Advance the cursor by the "Snapping interval" selected</Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">↓</KeyIcon>
        </IconRow>
        <IconRow mdxType="IconRow">
          <Or mdxType="Or" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">←</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Seek backwards
    <Sidenote mdxType="Sidenote">Rewind the cursor by the "Snapping interval" selected</Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">Q</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Quick-select
    <Sidenote mdxType="Sidenote">
      Select all entities between two beats. Only selects entities in the
      current view (blocks/mines/obstacles in Notes view, lighting events in
      Events view)
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">J</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Jump to a specific beat
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">
          <MetaKey mdxType="MetaKey" />
        </KeyIcon>
        <KeyIcon mdxType="KeyIcon">X</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Cut
    <Sidenote mdxType="Sidenote">
      Copies the active selection to the clipboard, and deletes the selected
      entities
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">
          <MetaKey mdxType="MetaKey" />
        </KeyIcon>
        <KeyIcon mdxType="KeyIcon">C</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Copy
    <Sidenote mdxType="Sidenote">Copies the active selection to the clipboard</Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">
          <MetaKey mdxType="MetaKey" />
        </KeyIcon>
        <KeyIcon mdxType="KeyIcon">V</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Paste
  </Shortcut>{' '}
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">Del</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Delete selection
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">Home</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Skip to the start
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">End</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Skip to the end
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">
          <MetaKey mdxType="MetaKey" />
        </KeyIcon>
        <KeyIcon mdxType="KeyIcon">Z</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Undo
    <Sidenote mdxType="Sidenote">Undoes the last action</Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Shift</KeyIcon>
          <KeyIcon mdxType="KeyIcon">
            <MetaKey mdxType="MetaKey" />
          </KeyIcon>
          <KeyIcon mdxType="KeyIcon">Z</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Redo
    <Sidenote mdxType="Sidenote">Reverts the last undo</Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <MetaKey mdxType="MetaKey" />
          </KeyIcon>
          <KeyIcon mdxType="KeyIcon">1</KeyIcon>
        </IconRow>
        <IconRow mdxType="IconRow">
          <Or mdxType="Or">Through</Or>
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <MetaKey mdxType="MetaKey" />
          </KeyIcon>
          <KeyIcon mdxType="KeyIcon">9</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Switch snapping interval
    <Sidenote mdxType="Sidenote">
      Set the snapping interval to a new value, ranging from{' '}
      <code>
        <MetaKey mdxType="MetaKey" /> + 1
      </code>{' '}
      for 1/16th beat, to{' '}
      <code>
        <MetaKey mdxType="MetaKey" /> + 9
      </code>{' '}
      for 4 bars
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <MetaKey mdxType="MetaKey" />
          </KeyIcon>
          <Plus mdxType="Plus" />
          <Mouse activeButton="scroll" mdxType="Mouse" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <Or mdxType="Or" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <MetaKey mdxType="MetaKey" />
          </KeyIcon>
          <KeyIcon mdxType="KeyIcon">↓</KeyIcon>
          <KeyIcon mdxType="KeyIcon">↑</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Scroll through snapping intervals
    <Sidenote mdxType="Sidenote">
      Sweep through available snapping intervals by holding{' '}
      <code>
        <MetaKey mdxType="MetaKey" />
      </code>{' '}
      and either spinning the mousewheel or using the arrow keys
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon type="square" mdxType="KeyIcon">Esc</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Clears the current selection
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">
          <MetaKey mdxType="MetaKey" />
        </KeyIcon>
        <KeyIcon mdxType="KeyIcon">B</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Create bookmark
    <Sidenote mdxType="Sidenote">
      Bookmarks add little flags to the waveform that let you quickly jump to
      different points in the map. You can right-click to delete existing
      bookmarks.
    </Sidenote>
  </Shortcut>
    </ShortcutTable>
    <h2 {...{
      "id": "notes-view-shortcuts"
    }}>{`Notes view shortcuts`}</h2>
    <p>{`These shortcuts are exclusive to placing blocks, mines, and obstacles in the “Notes” view.`}</p>
    <ShortcutTable mdxType="ShortcutTable">
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">W</KeyIcon>
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">A</KeyIcon>
          <KeyIcon mdxType="KeyIcon">S</KeyIcon>
          <KeyIcon mdxType="KeyIcon">D</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Set the direction for note blocks.
    <Sidenote mdxType="Sidenote">
      Diagonals can be achieved by holding multiple keys (eg.{' '}
      <InlineIcons mdxType="InlineIcons">
        <KeyIcon size="small" mdxType="KeyIcon">W</KeyIcon>
        <KeyIcon size="small" mdxType="KeyIcon">A</KeyIcon>
      </InlineIcons>{' '}
      for up-left)
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">F</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Set the note direction to a "face" block
    <Sidenote mdxType="Sidenote">
      While{' '}
      <InlineIcons mdxType="InlineIcons">
        <KeyIcon size="small" mdxType="KeyIcon">W</KeyIcon>
        <KeyIcon size="small" mdxType="KeyIcon">A</KeyIcon>
        <KeyIcon size="small" mdxType="KeyIcon">S</KeyIcon>
        <KeyIcon size="small" mdxType="KeyIcon">D</KeyIcon>
      </InlineIcons>{' '}
      choose cardinal directions, <KeyIcon size="small" mdxType="KeyIcon">F</KeyIcon> resets to a
      directionless block
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">Shift</KeyIcon>
        <Plus mdxType="Plus" />
        <Mouse mdxType="Mouse" />
      </IconRow>} mdxType="Shortcut">
    Look around
    <Sidenote mdxType="Sidenote">
      Holding shift allows you to pan the camera, like looking around in a
      first-person video game
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Shift</KeyIcon>
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">W</KeyIcon>
          <KeyIcon mdxType="KeyIcon">A</KeyIcon>
          <KeyIcon mdxType="KeyIcon">S</KeyIcon>
          <KeyIcon mdxType="KeyIcon">D</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Move around
    <Sidenote mdxType="Sidenote">
      Like Unreal Tournament!
      <br />
      You can also rise straight up with{' '}
      <InlineIcons mdxType="InlineIcons">
        <KeyIcon size="small" mdxType="KeyIcon">R</KeyIcon>
      </InlineIcons>
      , or fall straight down with <InlineIcons mdxType="InlineIcons">
        <KeyIcon size="small" mdxType="KeyIcon">F</KeyIcon>
      </InlineIcons>
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Backspace</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Reset view
    <Sidenote mdxType="Sidenote">Jump back to the original camera position</Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <MetaKey mdxType="MetaKey" />
          </KeyIcon>
          <KeyIcon mdxType="KeyIcon">A</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Select / Deselect all
    <Sidenote mdxType="Sidenote">
      Select all notes/mines/obstacles. Can also be used to deselect everything,
      if a current selection exists
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">R</KeyIcon>
        </IconRow>
        <IconRow mdxType="IconRow">
          <Or mdxType="Or" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">1</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Set tool to Red block
    <Sidenote mdxType="Sidenote">
      So that clicking on the placement grid creates red blocks
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">B</KeyIcon>
        </IconRow>
        <IconRow mdxType="IconRow">
          <Or mdxType="Or" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">2</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Set tool to Blue block
    <Sidenote mdxType="Sidenote">
      So that clicking on the placement grid creates blue blocks
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">3</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Set tool to Mine
    <Sidenote mdxType="Sidenote">So that clicking on the placement grid creates mines</Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">4</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Set tool to Obstacles
    <Sidenote mdxType="Sidenote">
      So that clicking on the placement grid creates obstacles (walls and
      ceilings)
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Tab</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Select next tool
    <Sidenote mdxType="Sidenote">
      Cycles between red block, blue block, mine, and obstacle
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Shift</KeyIcon>
          <KeyIcon mdxType="KeyIcon">Tab</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Select previous tool
    <Sidenote mdxType="Sidenote">
      Cycles between red block, blue block, mine, and obstacle
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">H</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Swap selection horizontally
    <Sidenote mdxType="Sidenote">
      Mirror the selected blocks/mines/obstacles from left to right
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">V</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Swap selection vertically
    <Sidenote mdxType="Sidenote">
      Mirror the selected blocks/mines/obstacles from top to bottom
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Page Up</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Jump forward 32 beats
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Page Down</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Jump backwards 32 beats
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <Mouse activeButton="scroll" mdxType="Mouse" />
        </IconRow>
      </>} mdxType="Shortcut">
    Scrub through time
    <Sidenote mdxType="Sidenote">
      Scroll the mousewheel to scrub through the song. Tweak the "snapping
      interval" control to change the precision.
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <Mouse activeButton="left" mdxType="Mouse" />
        </IconRow>
      </>} mdxType="Shortcut">
    Click an entity to select it
    <Sidenote mdxType="Sidenote">
      Entities are blocks, mines, and obstacles. Click again to deselect it
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <Mouse activeButton="right" mdxType="Mouse" />
        </IconRow>
      </>} mdxType="Shortcut">
    Right-click an entity to delete it
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <Mouse activeButton="middle" mdxType="Mouse" />
        </IconRow>
      </>} mdxType="Shortcut">
    Middle-click a block to toggle color
    <Sidenote mdxType="Sidenote">
      Turn a blue block red, or vice versa. Has no effect on mines and obstacles
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <OptionKey mdxType="OptionKey" />
          </KeyIcon>
          <Plus mdxType="Plus" />
          <Mouse activeButton="scroll" mdxType="Mouse" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <Or mdxType="Or" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <OptionKey mdxType="OptionKey" />
          </KeyIcon>
          <KeyIcon mdxType="KeyIcon">↓</KeyIcon>
          <KeyIcon mdxType="KeyIcon">↑</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Nudge selected notes
    <Sidenote mdxType="Sidenote">
      Sometimes, you'll want to shift the selection forwards or backwards a bit.
      By holding{' '}
      <code>
        <OptionKey mdxType="OptionKey" />
      </code>{' '}
      and using the arrow keys or spinning the mousewheel, you can nudge notes.{' '}
      <strong>Respects the snapping interval</strong>.
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">G</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Toggle "Grid" side-panel
    <Sidenote mdxType="Sidenote">
      Switches the right side-panel to show the grid configuration options{' '}
      <strong>Requires "Mapping Extensions" to be enabled</strong>.
    </Sidenote>
  </Shortcut>
    </ShortcutTable>
    <h2 {...{
      "id": "events-view-shortcuts"
    }}>{`Events view shortcuts`}</h2>
    <p>{`These shortcuts are used for placing lighting and ring events in the “Events” view.`}</p>
    <ShortcutTable mdxType="ShortcutTable">
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">A</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Switch to "Add" mode
    <Sidenote mdxType="Sidenote">
      There are two edit modes, "Add" and "Select". <br />
      <br />
      In "Add" mode, you can click to add events
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">S</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Switch to "Select" mode
    <Sidenote mdxType="Sidenote">
      There are two edit modes, "Add" and "Select". <br />
      <br />
      In "Select" mode, you can quickly batch-select groups of events
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">R</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Set light color to "Red"
    <Sidenote mdxType="Sidenote">Sets the color for new events in lighting tracks</Sidenote>
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">B</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Set light color to "Blue"
    <Sidenote mdxType="Sidenote">Sets the color for new events in lighting tracks</Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">1</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Select "On" tool
    <Sidenote mdxType="Sidenote">Sets the event type for new events in lighting tracks</Sidenote>
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">2</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Select "Off" tool
    <Sidenote mdxType="Sidenote">Sets the event type for new events in lighting tracks</Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">3</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Select "Flash" tool
    <Sidenote mdxType="Sidenote">
      Sets the event type for new events in lighting tracks
      <br />
      <br />
      "Flash" events grow to maximum brightness, and remain on afterwards
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<IconRow mdxType="IconRow">
        <KeyIcon mdxType="KeyIcon">4</KeyIcon>
      </IconRow>} mdxType="Shortcut">
    Select "Fade" tool
    <Sidenote mdxType="Sidenote">
      Sets the event type for new events in lighting tracks
      <br />
      <br />
      "Fade" events jump to maximum brightness and fade out, remaining off afterwards
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Tab</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Select next tool
    <Sidenote mdxType="Sidenote">Cycles between On, Off, Flash, and Fade</Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Shift</KeyIcon>
          <KeyIcon mdxType="KeyIcon">Tab</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Select previous tool
    <Sidenote mdxType="Sidenote">Cycles between On, Off, Flash, and Fade</Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">-</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Zoom out
    <Sidenote mdxType="Sidenote">
      The Events view shows a window of events, defaulting to 16 beats. You can
      increase that to 32 beats, for a wider view.
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">+</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Zoom in
    <Sidenote mdxType="Sidenote">
      Decrease the number of events shown in the Events window.
      <br />
      <br />
      Note that the shortcut can be typed as <code>shift</code> <code>=</code>, to
      form the <code>+</code>. Or use the numpad.
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Page Up</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Jump to the start of the current window
    <Sidenote mdxType="Sidenote">
      If you're already right at the start, this jumps to the previous window
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Page Down</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Jump to the start of the next window
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <MetaKey mdxType="MetaKey" />
          </KeyIcon>
          <KeyIcon mdxType="KeyIcon">A</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Select / deselect all
    <Sidenote mdxType="Sidenote">
      Select all events <strong>within the current window</strong>. Unlike in
      "Notes" view, this shortcut only selects what is visible.
      <br />
      <br />
      If any events are selected, this will deselect everything.
    </Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <OptionKey mdxType="OptionKey" />
          </KeyIcon>
          <Plus mdxType="Plus" />
          <Mouse activeButton="scroll" mdxType="Mouse" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <Or mdxType="Or" />
        </IconRow>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">
            <OptionKey mdxType="OptionKey" />
          </KeyIcon>
          <KeyIcon mdxType="KeyIcon">←</KeyIcon>
          <KeyIcon mdxType="KeyIcon">→</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Nudge selected events
    <Sidenote mdxType="Sidenote">
      Shift selected events forwards or backwards.{' '}
      <strong>Respects the snapping interval</strong>.
    </Sidenote>
  </Shortcut>
  {
        /*  */
      }
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">Z</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Toggle the "event window lock"
    <Sidenote mdxType="Sidenote">Loop playback within the current event window.</Sidenote>
  </Shortcut>
  <Shortcut keys={<>
        <IconRow mdxType="IconRow">
          <KeyIcon mdxType="KeyIcon">X</KeyIcon>
        </IconRow>
      </>} mdxType="Shortcut">
    Toggle the "laser lock"
    <Sidenote mdxType="Sidenote">
      Tie the left/right laser tracks together so that events in one affect the
      other.
    </Sidenote>
  </Shortcut>
    </ShortcutTable>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "general-shortcuts",
    level: 2,
    title: "General shortcuts",
    children: [
        
      ]
  },
{
    id: "notes-view-shortcuts",
    level: 2,
    title: "Notes view shortcuts",
    children: [
        
      ]
  },
{
    id: "events-view-shortcuts",
    level: 2,
    title: "Events view shortcuts",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Keyboard Shortcuts",
  "subtitle": "Master the editor"
}

