
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"4 min read","minutes":3.19,"time":191400,"words":638}
/* @jsx mdx */
import googleSearchImgSrc from '../images/google-image-search.png';
import unsplashSrc from '../images/unsplash.png';
import newSongModalSrc from '../images/new-song-modal.png';
import PreviousNextBar from '../../components/Docs/PreviousNextBar';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When you first visit `}<a parentName="p" {...{
        "href": "https://beatmapper.app"
      }}>{`Beatmapper.app`}</a>{`, you’ll be presented with 3 options:`}</p>
    <ul>
      <li parentName="ul">{`Load a demo track`}</li>
      <li parentName="ul">{`Import an existing map`}</li>
      <li parentName="ul">{`Create a new map`}</li>
    </ul>
    <p>{`For the quickest start, you can load the demo track, a short 1-minute edit of a public-domain dubstep track: `}<a parentName="p" {...{
        "href": "https://soundcloud.com/geoxor_official/only-now"
      }}>{`Only Now - Geoxor`}</a>{`. Please note that this map currently only has blocks (no lighting). I plan to add lighting shortly.`}</p>
    <p>{`You can also import a map you’ve downloaded from `}<a parentName="p" {...{
        "href": "https://beatsaver.com/"
      }}>{`Beat Saver`}</a>{`, or created using another mapper. Beatmapper doesn’t support mapping extensions, or any other custom mods, but any map that works in vanilla Beat Saber should be loadable and editable.`}</p>
    <p>{`While these options are great for getting a quick look at the editor and playing around, you’ll likely want to create your own map. From this point on, we’ll assume you’re creating a new map from scratch.`}</p>
    <h2 {...{
      "id": "file-prep"
    }}>{`File prep`}</h2>
    <p>{`Before we can start mapping, we need to get our assets in order. Specifically, we’ll need:`}</p>
    <ol>
      <li parentName="ol">{`A square cover art image in `}<inlineCode parentName="li">{`.jpg`}</inlineCode>{` format`}</li>
      <li parentName="ol">{`An audio file in `}<inlineCode parentName="li">{`.ogg`}</inlineCode>{` format`}</li>
    </ol>
    <h3 {...{
      "id": "cover-art"
    }}>{`Cover art`}</h3>
    <p>{`For cover art, the image you select needs to be square, with dimensions of at least 250×250 (I generally aim for at least 600×600, to ensure it looks sharp on any display).`}</p>
    <p>{`An easy way to tackle this is to grab the song’s official cover art, by searching for the song name and tabbing over to "images":`}</p>
    <img src={googleSearchImgSrc} alt="Google Images search" width={700} />
    <p>{`Depending on the cover art, this may or may not produce a desirable result. Another alternative is to search for a beautiful royalty-free image on a site like Unsplash:`}</p>
    <img src={unsplashSrc} alt="A search on Unsplash.com" caption="Unsplash has many beautiful images" width={700} />
    <p>{`Please note that no matter where you get your image from, you’ll need to crop that photo to be a square. You won’t be able to upload or share your map if your cover art is rectangular.`}</p>
    <h3 {...{
      "id": "song"
    }}>{`Song`}</h3>
    <p>{`Beat Saber requires that all songs are in `}<inlineCode parentName="p">{`.ogg`}</inlineCode>{` format, an obscure alternative to the more-common `}<inlineCode parentName="p">{`.mp3`}</inlineCode>{`.`}</p>
    <p>{`We’ll also need to learn a few pieces of information about our song:`}</p>
    <ul>
      <li parentName="ul">{`the song’s BPM (beats per minute)`}</li>
      <li parentName="ul">{`the song’s offset (the number of milliseconds before the song’s first beat).`}</li>
    </ul>
    <p>{`Our `}<a parentName="p" {...{
        "href": "/docs/song-prep"
      }}>{`Song Prep`}</a>{` documentation covers this process in depth. If you’re new to mapping, I’d recommend reading that guide in full, and then coming back here armed with a song file and all the info we need.`}</p>
    <h2 {...{
      "id": "creating-our-song"
    }}>{`Creating our song`}</h2>
    <p>{`To create your first song, select the middle “Create from scratch” option from the Beatmapper homepage:`}</p>
    <p>{`If you’ve already loaded the demo track or imported an existing map, you can use the “Create new song” button in the right side-panel:`}</p>
    <p>{`Either way, you’ll be prompted with a modal that asks you for a lot of information:`}</p>
    <img src={newSongModalSrc} alt="New song modal" width={450} />
    <p>{`You can drag-and-drop your song and cover art files onto their respective boxes to associate them. You can also click the boxes to select them through the traditional file-picker.`}</p>
    <p>{`The first three fields—Song name, Song sub-name, and Artist name—are cosmetic. They appear in-game, but have no functional effect. You can fill them with whichever values you’d like.`}</p>
    <p>{`It’s important to get the BPM and Offset values correct. For more information on these values, please consult our `}<a parentName="p" {...{
        "href": "/docs/song-prep"
      }}>{`Song Prep`}</a>{` guide.`}</p>
    <p>{`Finally, a difficulty must be selected. You can create as many difficulties as you’d like, so don’t worry too much about this choice! You’re selecting the one you’d like to start with.`}</p>
    <p>{`After submitting this form, you’ll be taken to the `}<strong parentName="p">{`notes view`}</strong>{`. The next section of our guide covers this view.`}</p>
    <PreviousNextBar currentPageId="getting-started" mdxType="PreviousNextBar" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "file-prep",
    level: 2,
    title: "File prep",
    children: [
        {
              id: "cover-art",
              level: 3,
              title: "Cover art",
              children: [
                      
                    ]
            },
      {
              id: "song",
              level: 3,
              title: "Song",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "creating-our-song",
    level: 2,
    title: "Creating our song",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Getting started",
  "subtitle": "User Manual"
}

