
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.995,"time":59700,"words":199}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Many online web services transmit the files you select to be stored in a remote server. The owner of that webserver then bears certain responsibilities around managing the content, ensuring that laws are followed (especially copyright / piracy laws).`}</p>
    <p>{`Beatmapper runs entirely on your device: there is no server-based storage. When you select files on your computer (like the `}<inlineCode parentName="p">{`.ogg`}</inlineCode>{` song file, or `}<inlineCode parentName="p">{`.jpg`}</inlineCode>{` cover art image), the browser processes those files, and creates a local copy stored in the browser’s memory (specifically, indexedDB, which is a browser technology similar to cookies which can store binary data like audio and images).`}</p>
    <p>{`Put simply, the files you select are never transmitted over the network. This means that Beatmapper does not receive them, nor are we able to “block” or “delete” copyrighted materials. Beatmapper is akin to an audio editing program, like Audacity or Adobe Audition, in how it functions.`}</p>
    <h2 {...{
      "id": "exceptions"
    }}>{`Exceptions`}</h2>
    <p>{`The one exception to this policy is the demo track, Only Now by Geoxor. This file is persisted on our web server and served like any other assets (eg. Beatmapper fonts and images). This song is public domain, and is released without copyright claim.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "exceptions",
    level: 2,
    title: "Exceptions",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Content Policy"
}

