
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.335,"time":20100,"words":67}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Beatmapper is a web-based editor for Beat Saber™. It lets you create your own "maps"—block choreographies—to be used in-game for your favourite songs.`}</p>
    <p><strong parentName="p">{`This project is no longer actively maintained.`}</strong>{` The software hasn’t been significantly updated since late 2020. It might still work, but you should definitely test everything out, `}<em parentName="p">{`including publishing`}</em>{`, before you spend time working on a map.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {
  "title": "Introduction",
  "subtitle": "About Beatmapper"
}

