
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.995,"time":119700,"words":399}
/* @jsx mdx */


const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Beatmapper is proud to be minimally invasive from a privacy standpoint. We collect very little information, and operate in a manner similar to a desktop standalone application.`}</p>
    <p>{`This document covers the data we collect and how we use it.`}</p>
    <p><strong parentName="p">{`Last modified: November 10 2019`}</strong></p>
    <h2 {...{
      "id": "remotely-collected-data"
    }}>{`Remotely collected data`}</h2>
    <p>{`Beatmapper does not own or operate a database; as such, the information we collect is very limited.`}</p>
    <p>{`When your computer makes a request to our web server, some basic data is recorded to identify your machine. This information includes the time that you visited, the pages visited, and your IP address.`}</p>
    <p>{`Beatmapper does not feature any client-side analytics; we don’t use Google Analytics or any similar services.`}</p>
    <p>{`The minimal server-side analytics data collected is used primarily to give the author (Josh Comeau) insight into how many people are using the tool, and how that number changes over time.`}</p>
    <p>{`Unlike many websites, Beatmapper does not have a user authentication / signup system. As such, I should never handle any personally-identifiable information (PII).`}</p>
    <h2 {...{
      "id": "locally-saved-data"
    }}>{`Locally saved data`}</h2>
    <p>{`Beatmapper doesn’t use any cookies, but we do use another form of persistent tracking: IndexedDB.`}</p>
    <p>{`There are two pertinent differences between these two technologies:`}</p>
    <ol>
      <li parentName="ol">{`Cookies are sent to the server with every request, and so this data is typically used on the backend to track you / perform aggregate calculations. IndexedDB data never leaves your device`}</li>
      <li parentName="ol">{`IndexedDB supports `}<em parentName="li">{`much`}</em>{` larger file-sizes, and it even supports binary file sizes. This allows us to store all the assets you provide - song files and cover art - in this persistent storage.`}</li>
    </ol>
    <p>{`All of the data (including files) provided when creating songs are stored this way. I also persist local application state, so that settings can be "sticky"; if you lower the editor volume today, I remember that setting and restore that value tomorrow.`}</p>
    <p>{`All of this is done for your convenience, and is used exclusively by you. Because this data never leaves your device, it serves no purpose for me.`}</p>
    <p>{`In the future I may wish to collect aggregate analytics about this information, to improve the product. For example, one of the editor controls allows users to increase/decrease the spacing between notes. I have no idea how many people use this feature. If the answer is near-zero, it probably makes sense to remove it, and replace it with something more useful.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "remotely-collected-data",
    level: 2,
    title: "Remotely collected data",
    children: [
        
      ]
  },
{
    id: "locally-saved-data",
    level: 2,
    title: "Locally saved data",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Privacy Policy"
}

