
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"9 min read","minutes":8.91,"time":534600,"words":1782}
/* @jsx mdx */
import PreviousNextBar from '../../components/Docs/PreviousNextBar';
import notesViewSrc from '../images/notes-view.png';
import jumpIconsSrc from '../images/jump-icons.png';
import snappingIntervalSrc from '../images/snapping-interval.png';
import snappingIntervalShortcutsSrc from '../images/snapping-interval-shortcuts.png';
import movementSrc from '../images/movement.gif';
import placeBlockSrc from '../images/place-block.gif';
import tweakColorSrc from '../images/tweak-color-and-direction.gif';
import swapBlockColorSrc from '../images/swap-block-color.gif';
import dragDirectionSrc from '../images/drag-direction.gif';
import placeMineSrc from '../images/place-mine.gif';
import placeObstacleSrc from '../images/place-obstacle.gif';
import placeCeilingSrc from '../images/place-ceiling.gif';
import obstacleDurationSrc from '../images/obstacle-duration.gif';
import changeDurationBtnSrc from '../images/change-duration-btn.gif';
import selectionIntroSrc from '../images/select-intro.gif';
import bulkSelectSrc from '../images/bulk-select.gif';
import quickSelectSrc from '../images/quick-select.gif';
import bottomBarSrc from '../images/bottom-bar.png';
import tickToggleSrc from '../images/tick-toggle.png';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`After creating a song, or selecting a song from the homescreen, you’ll be tossed into the `}<strong parentName="p">{`“Notes” view`}</strong>{`. This view allows you to add `}<em parentName="p">{`blocks`}</em>{`, `}<em parentName="p">{`mines`}</em>{`, and `}<em parentName="p">{`obstacles`}</em>{` to your map (AKA all the stuff you interact with in-game):`}</p>

    <img src={notesViewSrc} />
    <p>{`The main area is taken up by a 3D representation of the notes in your song. You’ll see the `}<strong parentName="p">{`placement grid`}</strong>{` in the center; these 12 squares represent the possible positions for blocks and other elements. You can `}<strong parentName="p">{`left-click`}</strong>{` the square to add a block to a specific square, at the current moment in time.`}</p>
    <h2 {...{
      "id": "navigating-time"
    }}>{`Navigating time`}</h2>
    <p>{`There are many ways to move through time in the song:`}</p>
    <h4 {...{
      "id": "1-media-controls"
    }}>{`1. Media controls`}</h4>
    <p>{`In the center of the bottom control panel, you’ll find buttons for playing / pausing the track (shortcut: `}<inlineCode parentName="p">{`Space`}</inlineCode>{`), jumping forwards/backwards by 32 beats (`}<inlineCode parentName="p">{`PageUp`}</inlineCode>{` / `}<inlineCode parentName="p">{`PageDown`}</inlineCode>{`), and jumping straight to the beginning or end (`}<inlineCode parentName="p">{`Home`}</inlineCode>{` / `}<inlineCode parentName="p">{`End`}</inlineCode>{`).`}</p>

    <img src={jumpIconsSrc} width={478 / 2} />
    <h4 {...{
      "id": "2-scrubbing-the-waveform"
    }}>{`2. Scrubbing the waveform`}</h4>
    <p>{`At the bottom of the view, you’ll see a waveform representation of the loaded audio file.`}</p>
    <p>{`You can `}<strong parentName="p">{`click and drag`}</strong>{` to scrub through this view, or simply click to set the current time to the corresponding point. This is probably the best way to quickly get to a specific area of the song.`}</p>
    <h4 {...{
      "id": "3-seeking"
    }}>{`3. Seeking`}</h4>
    <p>{`The most common way to navigate around is known as “seeking”. Seeking works by moving the cursor forwards or backwards by a `}<em parentName="p">{`specific number of beats`}</em>{`, which defaults to 1/2 beat.`}</p>
    <p>{`You can `}<strong parentName="p">{`scroll the mousewheel`}</strong>{` to seek - every tick up will send you forwards by `}<em parentName="p">{`1 increment`}</em>{`, while mouse-wheeling in the opposite direction will seek backwards.`}</p>
    <p>{`You can also use the arrow keys! `}<strong parentName="p">{`Up`}</strong>{` and `}<strong parentName="p">{`right`}</strong>{` are used to go forwards, while `}<strong parentName="p">{`down`}</strong>{` and `}<strong parentName="p">{`left`}</strong>{` will go backward.`}</p>
    <p>{`You can change the “snapping interval”, which affects how far each tick takes you.`}</p>

    <img src={snappingIntervalSrc} width={388 / 2} />
    <p>{`Clicking on the dropdown will show you all available options, as well as the corresponding keyboard shortcuts:`}</p>

    <img src={snappingIntervalShortcutsSrc} width={584 / 2} />
    <blockquote>
      <p parentName="blockquote">{`You can also shift between snapping intervals by holding `}<inlineCode parentName="p">{`ctrl`}</inlineCode>{` (or `}<inlineCode parentName="p">{`⌘`}</inlineCode>{` on Mac) and spinning the mousewheel.`}</p>
    </blockquote>
    <h2 {...{
      "id": "navigating-space"
    }}>{`Navigating space`}</h2>
    <p>{`By default, the camera will be facing the placement grid head-on. The main benefit of a 3D editor is the ability to move around, though!`}</p>
    <p>{`You can move the camera by `}<strong parentName="p">{`holding shift`}</strong>{` and `}<strong parentName="p">{`using WASD`}</strong>{`, while looking around with the mouse, as you might in a first-person shooter video game. You can also use `}<strong parentName="p">{`R`}</strong>{` to rise up, and `}<strong parentName="p">{`F`}</strong>{` to fall down (while still holding shift).`}</p>

    <img src={movementSrc} width={480} caption="Like playing Unreal Tournament, only you can fly" />
    <p>{`At any point, you can press `}<strong parentName="p">{`backspace`}</strong>{` to reset the camera’s position to the default head-on view.`}</p>
    <h2 {...{
      "id": "placing-blocks"
    }}>{`Placing blocks`}</h2>
    <p>{`To place a block, click on the corresponding square in the `}<em parentName="p">{`placement grid`}</em>{`:`}</p>

    <img src={placeBlockSrc} width={480} />
    <p>{`Blocks can be placed in 8 cardinal directions, as well as a directionless “face” block. Blocks also come in two colors, corresponding to which saber they need to be struck with. You can select color and direction using the right sidebar:`}</p>

    <img src={tweakColorSrc} width={480} />
    <p>{`You’ll be changing color and direction quite a lot while mapping, so a number of conveniences are provided. First, you can use `}<strong parentName="p">{`keyboard shortcuts`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`To switch between red and blue blocks, you can press the `}<inlineCode parentName="li">{`R`}</inlineCode>{` and `}<inlineCode parentName="li">{`B`}</inlineCode>{` keys (or the `}<inlineCode parentName="li">{`1`}</inlineCode>{` and `}<inlineCode parentName="li">{`2`}</inlineCode>{` keys). You can also press `}<inlineCode parentName="li">{`tab`}</inlineCode>{` to move forward between tools (including mines and obstacles, which we’ll learn about shortly), or `}<inlineCode parentName="li">{`shift+tab`}</inlineCode>{` to move backwards between tools.`}</li>
      <li parentName="ul">{`To select a block direction, you can use the letters `}<inlineCode parentName="li">{`WASD`}</inlineCode>{`. For example, you’d press `}<inlineCode parentName="li">{`W`}</inlineCode>{` to select an “up” direction block. For diagonals, you can hold multiple keys: to select a top-left block, press both `}<inlineCode parentName="li">{`W`}</inlineCode>{` and `}<inlineCode parentName="li">{`A`}</inlineCode>{` at the same time.`}</li>
    </ul>
    <p>{`You can swap the color of an existing block by clicking on it with the `}<strong parentName="p">{`middle mouse button`}</strong>{`:`}</p>

    <img src={swapBlockColorSrc} width={474} caption="Clicking the middle mouse button" />
    <p>{`Finally, you can `}<strong parentName="p">{`click and drag`}</strong>{` to dynamically select a block direction. This is my personal favourite way to place blocks:`}</p>

    <img src={dragDirectionSrc} width={474} />
    <h2 {...{
      "id": "placing-mines-and-obstacles"
    }}>{`Placing mines and obstacles`}</h2>
    <p>{`There are two other tools we need to familiarize ourselves with: `}<strong parentName="p">{`mines`}</strong>{` and `}<strong parentName="p">{`obstacles`}</strong>{`.`}</p>
    <p>{`Mines are meant to be avoided by the sabers. You can select them from the right sidebar, or press `}<inlineCode parentName="p">{`3`}</inlineCode>{` to select them. You can also cycle through tools with `}<inlineCode parentName="p">{`tab`}</inlineCode>{` and `}<inlineCode parentName="p">{`shift+tab`}</inlineCode>{`.`}</p>
    <p>{`Mines don’t have colors or directions, but otherwise they work just like blocks:`}</p>

    <img src={placeMineSrc} width={480} />
    <p>{`Obstacles are a bit different - they take up multiple cells at the same time, and come in two variants: `}<strong parentName="p">{`walls`}</strong>{` and `}<strong parentName="p">{`ceilings`}</strong>{`.`}</p>
    <p>{`To place a wall, click and drag across the `}<em parentName="p">{`bottom 2 rows`}</em>{` in the placement grid:`}</p>

    <img src={placeObstacleSrc} width={480} />
    <blockquote>
      <p parentName="blockquote">{`You’ll notice that for walls, you’re limited to placing walls that are 1 or 2 columns wide, no wider. This is a safety precaution; 3-column-thick walls can be hazardous, as folks try to leap out of the way. If you really want to place a super-wide wall, you’ll need to do it in another editor.`}</p>
    </blockquote>
    <p>{`Ceilings are placed the same way, but by clicking on the top row of squares. You can flip between ceilings and walls by moving the mouse up and down, before releasing:`}</p>

    <img src={placeCeilingSrc} width={480} />
    <h3 {...{
      "id": "changing-obstacle-duration"
    }}>{`Changing obstacle duration`}</h3>
    <p>{`By default, Obstacles will be `}<strong parentName="p">{`4 beats long`}</strong>{`.`}</p>
    <p>{`You can resize them after placing the obstacle by clicking and dragging to the left or right:`}</p>

    <img src={obstacleDurationSrc} width={480} />
    <blockquote>
      <p parentName="blockquote">{`This control respects the “snapping interval” selected in the bottom-left. If you wish to have more precision around obstacle duration, you can tweak this control.`}</p>
    </blockquote>
    <p>{`You can also change obstacle duration by clicking to select it, and using the “Change duration” button in the side panel:`}</p>

    <img src={changeDurationBtnSrc} width={480} />
    <blockquote>
      <p parentName="blockquote">{`When you change the duration of an obstacle, it sets the new default duration, so that the next new obstacle you create will be the same size. This allows you to quickly place multiple obstacles of the same length.`}</p>
    </blockquote>
    <h2 {...{
      "id": "selections-for-bulk-editing"
    }}>{`Selections for bulk editing`}</h2>
    <p>{`Sometimes, you’ll want to perform bulk actions on a collection of blocks/mines/obstacles. You may wish to copy/paste a section of notes, or delete everything after a certain point, or swap a section horizontally or vertically.`}</p>
    <p>{`Beatmapper’s solution for this problem is called “selections”. You can add items to the current selection by `}<strong parentName="p">{`left-clicking`}</strong>{` them. Clicking again will `}<em parentName="p">{`deselect`}</em>{` the item.`}</p>

    <img src={selectionIntroSrc} width={480} caption="Selected items are indicated with a yellow glow" />
    <blockquote>
      <p parentName="blockquote">{`Keep an eye on the right side-panel: It updates to reflect the current selection, and offers special actions that can be performed on the current selection.`}</p>
    </blockquote>
    <p>{`As a convenience, you can `}<strong parentName="p">{`click and hold`}</strong>{` to select (or deselect) many items at once:`}</p>

    <img src={bulkSelectSrc} width={480} caption="Blocks, obstacles, and mines can all be selected" />
    <p>{`To `}<strong parentName="p">{`clear your selection`}</strong>{`, press the `}<inlineCode parentName="p">{`Escape`}</inlineCode>{` key on your keyboard. You can also toggle between selecting all notes and no notes with `}<inlineCode parentName="p">{`ctrl+A`}</inlineCode>{` (`}<inlineCode parentName="p">{`⌘+A`}</inlineCode>{` on Mac)`}</p>
    <p>{`To `}<strong parentName="p">{`delete selected items`}</strong>{`, you can use the `}<inlineCode parentName="p">{`Delete`}</inlineCode>{` key. Alternatively, you can also right-click items to delete them.`}</p>
    <p>{`If you make a mistake deleting items, you can undo with `}<inlineCode parentName="p">{`ctrl+Z`}</inlineCode>{` or `}<inlineCode parentName="p">{`⌘+Z`}</inlineCode>{`.`}</p>
    <p>{`You can `}<strong parentName="p">{`copy and paste`}</strong>{` using the standard shortcuts for your operating system:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ctrl+c`}</inlineCode>{` or `}<inlineCode parentName="li">{`⌘+c`}</inlineCode>{` to copy`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ctrl+v`}</inlineCode>{` or `}<inlineCode parentName="li">{`⌘+v`}</inlineCode>{` to cut (copies to the clipboard and deletes the blocks)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ctrl+p`}</inlineCode>{` or `}<inlineCode parentName="li">{`⌘+p`}</inlineCode>{` to paste`}</li>
    </ul>
    <h3 {...{
      "id": "quick-select"
    }}>{`Quick-select`}</h3>
    <p>{`It can be tedious to drag your mouse over all notes in a given section. To make life easier, you can use the “Quick-select” button in the right side-panel (Keyboard shortcut `}<inlineCode parentName="p">{`Q`}</inlineCode>{`). This will prompt you to enter a `}<em parentName="p">{`range of beats`}</em>{` to select:`}</p>

    <img src={quickSelectSrc} width={480} />
    <p>{`The value you enter must be in the form "start-end":`}</p>
    <table>
  <thead>
    <tr>
      <th>Valid</th>
      <th>Invalid</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>4-12</td>
      <td>4 12</td>
    </tr>
    <tr>
      <td>0-100</td>
      <td>0 to 100</td>
    </tr>
    <tr>
      <td>32-64</td>
      <td>64-32</td>
    </tr>
    <tr>
      <td>128-256</td>
      <td>32-64 128-256</td>
    </tr>
  </tbody>
    </table>
    <p>{`As a convenience, you can also enter a single number (eg. `}<inlineCode parentName="p">{`64`}</inlineCode>{`) to select `}<em parentName="p">{`everything starting from`}</em>{` a given beat number.`}</p>
    <blockquote>
      <p parentName="blockquote">{`When you provide a range, it will `}<em parentName="p">{`include`}</em>{` any blocks on the start beat, but `}<em parentName="p">{`exclude`}</em>{` any blocks on the end beat. While this behaviour might be a bit confusing at first, it’s `}<strong parentName="p">{`much`}</strong>{` easier to work with, since it means you can copy/paste selections end-to-end without overlaps.`}</p>
    </blockquote>
    <h2 {...{
      "id": "editor-controls-and-information"
    }}>{`Editor controls and information`}</h2>
    <p>{`At the very bottom of the screen, you’ll find some numbers and controls:`}</p>

    <img src={bottomBarSrc} />
    <p>{`The left side gives you some stats about your map, while the right side offers a few controls:`}</p>
    <ul>
      <li parentName="ul">{`Play a tick sound-effect for every block`}</li>
      <li parentName="ul">{`Increase or decrease the spacing between beats`}</li>
      <li parentName="ul">{`Speed up or slow down the audio file`}</li>
      <li parentName="ul">{`Control the song volume`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Note that all these controls affect the editing experience only. They don’t affect the map itself, so none of these controls will have any effect in-game.`}</p>
    </blockquote>
    <h3 {...{
      "id": "tick-sound-effect-caveat"
    }}>{`Tick sound effect caveat`}</h3>
    <p>{`Among the miscellaneous helpers, you’ll notice the ability to toggle a “tick” sound effect for every block:`}</p>

    <img src={tickToggleSrc} width={274 / 2} />
    <p>{`The tick sound may be slightly off-beat; I believe this is true for all editors, because having the audio and video perfectly synchronized is a devilishly hard challenge. `}<strong parentName="p">{`It is not recommended for using the tick sound effect to calculate BPM or offset`}</strong>{`; I’ve written an `}<a parentName="p" {...{
        "href": "/docs/song-prep"
      }}>{`extensive guide`}</a>{` covering how to accurately come up with BPM and offset. The tick sound effect is designed with a single purpose in mind: to give you a sense of the rhythm of your notes, to make sure they align with key elements in the song.`}</p>
    <h2 {...{
      "id": "additional-learning"
    }}>{`Additional learning`}</h2>
    <p>{`This guide has only scratched the surface of what you can do in Beatmapper! After you’ve had some practice with the editor, it is recommended that you check out the full list of `}<a parentName="p" {...{
        "href": "/docs/keyboard-shortcuts"
      }}>{`keyboard shortcuts`}</a>{` to learn the full suite of controls.`}</p>
    <p>{`Next up, we’ll be looking at the `}<strong parentName="p">{`events view`}</strong>{`, to learn about creating custom lighting.`}</p>
    <PreviousNextBar currentPageId="notes-view" mdxType="PreviousNextBar" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "navigating-time",
    level: 2,
    title: "Navigating time",
    children: [
        
      ]
  },
{
    id: "navigating-space",
    level: 2,
    title: "Navigating space",
    children: [
        
      ]
  },
{
    id: "placing-blocks",
    level: 2,
    title: "Placing blocks",
    children: [
        
      ]
  },
{
    id: "placing-mines-and-obstacles",
    level: 2,
    title: "Placing mines and obstacles",
    children: [
        {
              id: "changing-obstacle-duration",
              level: 3,
              title: "Changing obstacle duration",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "selections-for-bulk-editing",
    level: 2,
    title: "Selections for bulk editing",
    children: [
        {
              id: "quick-select",
              level: 3,
              title: "Quick-select",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "editor-controls-and-information",
    level: 2,
    title: "Editor controls and information",
    children: [
        {
              id: "tick-sound-effect-caveat",
              level: 3,
              title: "Tick sound effect caveat",
              children: [
                      
                    ]
            }
      ]
  },
{
    id: "additional-learning",
    level: 2,
    title: "Additional learning",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "The “Notes” View",
  "subtitle": "User Manual"
}

