
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"3 min read","minutes":2.265,"time":135900,"words":453}
/* @jsx mdx */
import fastWallsSrc from './images/fast-walls.png';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`By default, walls pop into existence a few beats ahead of the player, and move at the same speed as the notes towards the player. Fast walls offer a way to tweak this behavior, and send walls shooting quickly towards the player.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Fast walls are a very advanced feature, with many caveats.`}</p>
      <p parentName="blockquote">{`For one thing, you risk injuring players who are startled by how quickly an obstacle flies towards them!`}</p>
      <p parentName="blockquote">{`Additionally, they exploit a loophole in the game’s programming, and will likely be patched in the future. Using fast walls might make your maps unplayable.`}</p>
    </blockquote>
    <h3 {...{
      "id": "enabling-fast-walls"
    }}>{`Enabling fast walls`}</h3>
    <p>{`By default, you are not able to place fast walls.`}</p>
    <p>{`Head into the “Map settings” page and scroll down to tick the “Enable Fast Walls” checkbox.`}</p>
    <h3 {...{
      "id": "toggling-fast-walls"
    }}>{`Toggling fast walls`}</h3>
    <p>{`To set a wall as “fast”, select it and then click the “Toggle Fast Walls” button in the right side-panel. Fast walls are differentiated with a large “F” in their center:`}</p>

    <img src={fastWallsSrc} width={600} />
    <h3 {...{
      "id": "working-with-fast-walls"
    }}>{`Working with fast walls`}</h3>
    <p>{`Simply toggling a wall as “fast” won’t guarantee that it moves by the player quickly. The `}<em parentName="p">{`speed`}</em>{` of the wall depends on its `}<em parentName="p">{`duration`}</em>{` and `}<em parentName="p">{`start beat offset`}</em>{`.`}</p>
    <p>{`By default, notes and obstacles spawn 2 beats away from the player. If a note is to be struck on Beat 16, it spawns in the distance on beat 14. “Start beat offset” is a way to tweak that value; it defaults to 0, and you can push the offset back further with positive numbers; a “start beat offset” of 1 means that notes and obstacles start 3 beats away from the player instead of 2.`}</p>
    <p>{`With a default “start beat offset” of 0, here’s some rough guidelines:`}</p>
    <ul>
      <li parentName="ul">{`A wall that lasts for a single beat won’t be very fast at all, it moves at a normal-ish speed.`}</li>
      <li parentName="ul">{`A wall that lasts 2 beats moves noticeably faster; Shortly after spawning it accelerates towards the player`}</li>
      <li parentName="ul">{`A wall that lasts 3 beats moves very very quickly, though it still has the same spawn animation as any other wall.`}</li>
    </ul>
    <p>{`Fast walls are sort-of the Wild West; not many people understand them that deeply, and they’re seldomly used. Some experimentation is required to get a feel for them.`}</p>
    <p>{`They can provide neat effects in certain songs, especially when combined with `}<a parentName="p" {...{
        "href": "/docs/mods#mapping-extensions"
      }}>{`Mapping Extensions`}</a>{` to place the walls outside of the player’s personal space. But they carry risks: a future update might break any map that uses them! So please be cautious.`}</p>
    <p>{`Better support might come in the future, but until their future feels more solid, it’s not worth investing time into.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "enabling-fast-walls",
    level: 3,
    title: "Enabling fast walls",
    children: [
        
      ]
  },
{
    id: "toggling-fast-walls",
    level: 3,
    title: "Toggling fast walls",
    children: [
        
      ]
  },
{
    id: "working-with-fast-walls",
    level: 3,
    title: "Working with fast walls",
    children: [
        
      ]
  }
]

export const frontMatter = {
  "title": "Fast Walls",
  "subtitle": "💨"
}

