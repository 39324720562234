
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.38,"time":82800,"words":276}
/* @jsx mdx */
import qualityToggle from './videos/quality-toggle-2.mp4';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const YoutubeEmbed = makeShortcode("YoutubeEmbed");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "v03---lighting-previews-and-performance"
    }}>{`v0.3 - Lighting previews and performance`}</h1>
    <p>{`This is a really exciting release, because it addresses the 2 most commonly-requested features:`}</p>
    <ul>
      <li parentName="ul">{`Being able to preview the events/lighting you create`}</li>
      <li parentName="ul">{`Performance`}</li>
    </ul>
    <p>{`Let’s tackle these in turn:`}</p>
    <h4 {...{
      "id": "lighting-previews"
    }}>{`Lighting previews`}</h4>
    <p>{`In the “Events” view, you can click the toggle in the bottom-left corner to enable background lighting:`}</p>
    <YoutubeEmbed title="Enable lights" src="https://www.youtube.com/embed/ZcMW8v3OIVY" mdxType="YoutubeEmbed" />
    <p>{`The sliders beside this toggle allow you to customize the height of each row, and how see-through the event grid is:`}</p>
    <YoutubeEmbed title="Customize Lights" src="https://www.youtube.com/embed/ONwndWYNM5M" mdxType="YoutubeEmbed" />
    <p>{`In addition to a low-fidelity preview in the background, the `}<strong parentName="p">{`Demo view`}</strong>{` features high-fidelity “bloomed” lighting:`}</p>
    <YoutubeEmbed title="Demo view" src="https://www.youtube.com/embed/RgI-BhnURAc" mdxType="YoutubeEmbed" />
    <p>{`It’s still important to test your map’s lighting in-game, but you should have to do it much less often now that you can get a general sense right from the editor :D`}</p>
    <h4 {...{
      "id": "performance"
    }}>{`Performance`}</h4>
    <p>{`Beatmapper runs entirely in the browser, and it uses a language (Javascript) never meant for this type of graphic-intensive work. As such, the performance of Beatmapper has always been worse than competing editors.`}</p>
    <p>{`This new version introduces a “quality toggle”. You can switch between low/medium/high graphics settings:`}</p>

    <video src={qualityToggle} autoPlay muted loop controls style={{
      width: '100%'
    }} />
    <p>{`This setting changes a few things:`}</p>
    <ul>
      <li parentName="ul">{`Draws fewer blocks on the screen`}</li>
      <li parentName="ul">{`No more reflections on blocks`}</li>
      <li parentName="ul">{`No fog effect`}</li>
      <li parentName="ul">{`Greatly simplified lighting`}</li>
      <li parentName="ul">{`Optimized rendering on the scrubbable waveform`}</li>
    </ul>
    <p>{`There’s still a lot of room for improvement, and honestly I don’t expect a night/day difference… but for folks who were running at ~10–20fps before, it should hopefully help quite a bit!`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {
  "title": "Release Notes"
}

