import React from 'react';

const MineIcon = ({ size }) => {
  return (
    <svg width={size} viewBox="0 0 43 45" fill="none">
      <circle cx="23" cy="21" r="16" fill="#696969" />
      <path
        d="M23 21L17 6L20 5H23H26L29.5 6L32.5 8L34.5 9.5L36 12L37.5 14.5L38.5 17L39 20V22.5L38.5 25.5L37 28.5L23 21Z"
        fill="white"
        fillOpacity="0.17"
      />
      <path
        d="M38.7279 4.24264L36.0382 14.2808L28.6897 6.93237L38.7279 4.24264Z"
        fill="#595959"
      />
      <path
        d="M7.24264 4.24271L17.2808 6.93244L9.93237 14.2809L7.24264 4.24271Z"
        fill="#414141"
      />
      <path
        d="M3 33.1962L8.19615 24.1962L13.3923 33.1962L3 33.1962Z"
        fill="#414141"
      />
      <path
        d="M34.1962 41.3923L25.1962 36.1962L34.1962 31L34.1962 41.3923Z"
        fill="#414141"
      />
    </svg>
  );
};

export default MineIcon;
