
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"2 min read","minutes":1.595,"time":95700,"words":319}
/* @jsx mdx */
import googleSearchImgSrc from '../images/google-image-search.png';
import unsplashSrc from '../images/unsplash.png';
import newSongModalSrc from '../images/new-song-modal.png';
import PreviousNextBar from '../../components/Docs/PreviousNextBar';
import annotatedViewSrc from '../images/annotated-view.png';
import settingsModal from '../images/settings-modal.png';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`After either creating or importing your first map, you’ll find yourself in `}<strong parentName="p">{`the main editor`}</strong>{`. Here’s how it’s laid out:`}</p>

    <img src={annotatedViewSrc} />
    <h2 {...{
      "id": "main-view"
    }}>{`Main view`}</h2>
    <p>{`The `}<strong parentName="p">{`main view`}</strong>{` contains the content for the currently-selected tab. By default, we land on the `}<em parentName="p">{`Notes view`}</em>{`. We’ll learn much more about all the available views in this manual, but for now it’s sufficient to understand that this is where you’ll do your actual mapping.`}</p>
    <h2 {...{
      "id": "controls"
    }}>{`Controls`}</h2>
    <p>{`Below the main view, you’ll find the `}<strong parentName="p">{`navigation controls`}</strong>{`. It allows you to scrub forwards and backwards in time, trigger playback, and make editor customizations. We’ll explore this in more depth later on.`}</p>
    <h2 {...{
      "id": "sidebar"
    }}>{`Sidebar`}</h2>
    <p>{`The `}<strong parentName="p">{`Sidebar`}</strong>{` contains links to the 5 current views: Notes, Events, Demo, Map Details, and Download. You can hop between views using these links.`}</p>
    <p>{`Crucially, the sidebar contains two other links, located at the bottom:`}</p>
    <ul>
      <li parentName="ul">{`A “Settings” popup`}</li>
      <li parentName="ul">{`A link to these docs`}</li>
    </ul>
    <h3 {...{
      "id": "customizing-settings"
    }}>{`Customizing settings`}</h3>
    <p>{`Click the little gear icon near the bottom of the sidebar. You should see a little popup in the middle of the window:`}</p>

    <img src={settingsModal} width={876 / 2} />
    <p>{`If you’re using a lower-end machine (or, really, even a mid-tier machine), you’re probably better off choosing “low” to ensure that everything runs smoothly. Choosing a lower graphics setting reduces detail and draws less stuff on the screen at a time.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Processing delay” lets you tweak the timing so that the blocks are perfectly in-sync with the music. Please don’t use Beatmapper to try and sync your map! There are `}<a parentName="p" {...{
          "href": "/docs/song-prep#understanding-bpm-and-offset"
        }}>{`other tools`}</a>{` you can use to calculate the BPM and offset. Once you have those numbers, use this setting to ensure they’re in-sync in the editor.`}</p>
    </blockquote>
    <PreviousNextBar currentPageId="navigating-the-editor" mdxType="PreviousNextBar" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  {
    id: "main-view",
    level: 2,
    title: "Main view",
    children: [
        
      ]
  },
{
    id: "controls",
    level: 2,
    title: "Controls",
    children: [
        
      ]
  },
{
    id: "sidebar",
    level: 2,
    title: "Sidebar",
    children: [
        {
              id: "customizing-settings",
              level: 3,
              title: "Customizing settings",
              children: [
                      
                    ]
            }
      ]
  }
]

export const frontMatter = {
  "title": "Navigating the Editor",
  "subtitle": "User Manual"
}

