
import React from 'react'
import { mdx } from '@mdx-js/react'
export const readingTime = {"text":"1 min read","minutes":0.89,"time":53400,"words":178}
/* @jsx mdx */
import PreviousNextBar from '../../components/Docs/PreviousNextBar';

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const YoutubeEmbed = makeShortcode("YoutubeEmbed");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`While editing notes and events in the previous two views, it can be hard to get a sense for what the final product is like, how the map will play.`}</p>
    <p>{`While there is no substitute for trying your map in-game, I’m currently working on a new view that will come as close as possible. This view will showcase all of your lighting and ring events, as well as feature blocks spawning in a fashion similar to the game, taking things like Note Jump Speed and Start Beat Offset into account.`}</p>
    <p>{`This is an ambitious feature, and my time is limited, so it may be a while. A super-early preview is available, with support for some events:`}</p>
    <YoutubeEmbed title="Demo Preview" src="https://www.youtube.com/embed/iEOuTjrMogU" mdxType="YoutubeEmbed" />
    <blockquote>
      <p parentName="blockquote">{`WARNING: This video features flashing, strobing lights.`}</p>
    </blockquote>
    <p>{`If you’re comfortable with Three.js and wish to contribute to this functionality, your help would be `}<strong parentName="p">{`greatly`}</strong>{` appreciated! You can `}<a parentName="p" {...{
        "href": "mailto:joshwcomeau@gmail.com"
      }}>{`email me`}</a>{` and I’ll get you up to speed on the codebase :)`}</p>
    <PreviousNextBar currentPageId="demo-view" mdxType="PreviousNextBar" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
export const tableOfContents = (components={}) => [
  
]

export const frontMatter = {
  "title": "The “Demo” View",
  "subtitle": "User Manual"
}

